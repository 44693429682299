import React from 'react';

import { CountryField, CountriesWithStates } from '../CountryField/CountryField';
import { StateField } from '../StateField/StateField';

interface LicenseFieldsProps {
  types_of_licenses: Array<[string, string, {
    license_number: boolean,
    license_country: boolean,
    license_state: boolean,
    identification_expiration_date: boolean
  }]>;
  modelName: string;
  selectedTypeOfLicense?: string;
  selectedLicenseNumber?: string;
  selectedLicenseCountry?: string;
  selectedLicenseState?: string;
  selectedIdentificationExpirationDate?: string;
}

export const LicenseFields = ({
  modelName,
  selectedTypeOfLicense = '',
  selectedLicenseNumber = '',
  selectedLicenseCountry = '',
  selectedLicenseState = '',
  selectedIdentificationExpirationDate = ''
}: LicenseFieldsProps) => {
  const [typeOfLicense, setTypeOfLicense] = React.useState(selectedTypeOfLicense);
  const [licenseNumber, setLicenseNumber] = React.useState(selectedLicenseNumber);
  const [licenseCountry, setLicenseCountry] = React.useState(selectedLicenseCountry);
  const [licenseState, setLicenseState] = React.useState(selectedLicenseState);
  const [identificationExpirationDate, setIdentificationExpirationDate] = React.useState(selectedIdentificationExpirationDate);

  const typeOfLicenseObject = TYPES_OF_LICENSES.find(
    license => license[1] === typeOfLicense
  );

  const selectedCountryObject = CountriesWithStates.find(
    c => c.name === licenseCountry
  );

  const selectedCountryStates: Array<string> | undefined = selectedCountryObject
    ? selectedCountryObject.states
    : [];

  React.useEffect(() => {
    console.log('typeOfLicense', typeOfLicense)
    // If typeOfLicenseObject.identification_expiration_date is false, set identificationExpirationDate to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].identification_expiration_date) setIdentificationExpirationDate('');
    // If typeOfLicenseObject.license_number is false, set licenseNumber to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_number) setLicenseNumber('');
    // If typeOfLicenseObject.license_country is false, set licenseCountry to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_country) setLicenseCountry('');
    // If typeOfLicenseObject.license_state is false, set licenseState to ''
    if (typeOfLicenseObject && !typeOfLicenseObject[2].license_state) setLicenseState('');
  }, [typeOfLicense]);

  return (
    <div className="license-fields">
      <TypeOfLicenseFields
        types_of_licenses={TYPES_OF_LICENSES}
        modelName={modelName}
        setTypeOfLicense={setTypeOfLicense}
        typeOfLicense={typeOfLicense}
      />

      {/* Text Field identification_expiration_date */}
      {typeOfLicenseObject && typeOfLicenseObject[2].identification_expiration_date && (
        <div className="form-floating my-2">
          <input type="date"
            placeholder="Identification Expiration Date"
            name={`${modelName}[identification_expiration_date]`}
            value={identificationExpirationDate}
            onChange={(e) => setIdentificationExpirationDate(e.target.value)}
            className="form-control"
            required={"required"}
          />
          <label htmlFor={`${modelName}[identification_expiration_date]`}>
            Identification Expiration Date
          </label>
        </div>
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].identification_expiration_date && (
        <input type="hidden"
          name={`${modelName}[identification_expiration_date]`}
          value={identificationExpirationDate}
        />
      )}

      {/* license_number */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_number && (
        <div className="form-floating my-2">
          <input type="text"
            placeholder="License Number"
            name={`${modelName}[license_number]`}
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            className="form-control"
            required={"required"}
          />
          <label htmlFor={`${modelName}[license_number]`}>
            License Number
          </label>
        </div>
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_number && (
        <input type="hidden"
          name={`${modelName}[license_number]`}
          value={licenseNumber}
        />
      )}

      {/* license_country */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_country && (
        <CountryField
          selectedCountry={licenseCountry}
          modelName={modelName}
          fieldName="license_country"
          setCountryFunction={setLicenseCountry}
        />
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_country && (
        <input type="hidden"
          name={`${modelName}[license_country]`}
          value={licenseCountry}
        />
      )}

      {/* license_state */}
      {typeOfLicenseObject && typeOfLicenseObject[2].license_state && (
        <StateField
          states={selectedCountryStates}
          selectedState={licenseState}
          setStateFunction={setLicenseState}
          fieldName="license_state"
          modelName={modelName}
        />
      )}
      {typeOfLicenseObject && !typeOfLicenseObject[2].license_state && (
        <input type="hidden"
          name={`${modelName}[license_state]`}
          value={licenseState}
        />
      )}
    </div>
  );
}

const TypeOfLicenseFields = ({
  types_of_licenses,
  modelName,
  setTypeOfLicense,
  typeOfLicense
}) => {
  return (
    <div className="license-types">
      {types_of_licenses.map((license, index) => {
        const licenseDescription = license[0];
        const licenseValue = license[1];
        const licenseName = license[1].toLowerCase()
          .replace(" ", "_")
          .replace(".", "");
        const licenseId = `type_of_license_${licenseName}`;

        return (
          <div className="form-check" key={index}>
            <input type="radio"
              id={licenseId}
              value={licenseValue}
              className="form-check-input"
              required="required"
              onChange={() => setTypeOfLicense(licenseValue)}
              checked={`${licenseValue === typeOfLicense ? 'selected' : ''}`}
              name={`${modelName}[type_of_license]`}
            />
            <label className="form-check-label" htmlFor={licenseId}>
              {licenseDescription}
            </label>
          </div>
        )
      })}
    </div>
  );
}

const TYPES_OF_LICENSES = [
  [
    'A non-expired U.S. driver\'s license (including any driver\'s licenses issued by a commonwealth, territory, or possession of the United States)',
    'U.S. driver\'s license',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ],
  ['A non-expired identification document issued by a U.S. state or local government, or Indian Tribe',
    'U.S. identification document',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ],
  [
    'A non-expired passport issued by the U.S. government',
    'U.S. passport',
    {
      license_number: true,
      license_country: true,
      license_state: false,
      identification_expiration_date: true
    }
  ],
  [
    'A non-expired passport issued by a foreign government (only when an individual does not have one of the other three forms of identification listed above).', 'Foreign passport',
    {
      license_number: true,
      license_country: true,
      license_state: true,
      identification_expiration_date: true
    }
  ]
]